import { createAction, props } from '@ngrx/store';
import { CampaignDetails, CampaignUpdateRequest, CancelTrial, CreateCampaign, ReserveTrial, TrialBundle } from '@ra-state';

export const reserveTrial = createAction('[TRIALS] Reserve Trial', props<{ payload: ReserveTrial }>());

export const cancelReservation = createAction('[TRIALS] Cancel Trial Reservation', props<{ payload: CancelTrial }>());

export const createCampaign = createAction('[TRIALS] Create Trial Campaign', props<{ payload: CreateCampaign }>());

export const getCampaignDetails = createAction('[TRIALS] Get Campaign Details', props<{ payload: string }>());
export const setCampaignDetails = createAction('[TRIALS] Set Campaign Details', props<{ payload: CampaignDetails }>());

export const pauseCampaign = createAction('[TRIALS] Pause Trial Campaign', props<{ campaignId: string }>());
export const resumeCampaign = createAction('[TRIALS] Resume Trial Campaign', props<{ campaignId: string }>());
export const endCampaign = createAction('[TRIALS] End Trial Campaign', props<{ campaignId: string }>());

export const getTrialBundles = createAction('[TRIALS] Get Trial Bundle');
export const setTrialBundles = createAction('[TRIALS] Set Trial Bundle', props<{ payload: TrialBundle[] }>());

export const updateCampaign = createAction('[TRIALS] Update Trial Campaign Name And Description', props<{ payload: CampaignUpdateRequest }>());

export const refreshTrialGrid = createAction('[TRIALS] Refresh Trial Grid');
export const clearTrialGridRefresh = createAction('[TRIALS] Clear Trial Grid Refresh');