import { Action, createAction, props } from '@ngrx/store';
import { IDialogConfig, NotificationType } from '@ra-web-tech-ui-toolkit/components';
import { ConfirmModalConfig } from '../common-admin.model';

export const openConfirmDialog = createAction('[MODAL] Confirm Dialog', props<{ payload: ConfirmModalConfig }>());

export const openAlertDialog = createAction('[MODAL] Alert Dialog', props<{ payload: any }>());
export const openWebTechDialog = createAction(
  '[MODAL] WebTech Dialog',
  props<{
    config: IDialogConfig;
    notificationType?: NotificationType;
    mainBtnHandler?: () => void;
    closeHandler?: Action;
  }>(),
);
