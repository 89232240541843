import { ActionReducerMap } from '@ngrx/store';
import { AppState } from './common-admin.model';
import { controlPageReducer } from './control-page';
import { featureFlagsReducer } from './feature-flags/feature-flags.reducer';
import { reportsReducer } from './reports';
import { sessionManagementReducer } from './session-management/session-management.reducer';
import { signalrReducer } from './signalr';
import { snackBarReducer } from './snackbar/snackbar.reducer';
import { tenantStateReducer } from './tenant';
import { trialsReducer } from './trials/trials.reducer';
import { userStateReducer } from './user';

export const reducers: ActionReducerMap<AppState> = {
  controlPage: controlPageReducer,
  signalR: signalrReducer,
  snackBarData: snackBarReducer,
  user: userStateReducer,
  trials: trialsReducer,
  reports: reportsReducer,
  tenant: tenantStateReducer,
  featureFlags: featureFlagsReducer,
  sessionManagement: sessionManagementReducer,
};
