import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ReportFacade, displayMessage } from '@ra-state';
import { DataService } from '@services/data.service';
import { Observable, combineLatest, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CommandRequestBuilderService } from 'src/app/services/command-request-builder.service';
import { TrialEvents } from 'src/app/services/command-request.service';
import { CampaignDetails, IDomainMessage, IDomainUpdateResponse, TrialBundle } from '../common-admin.model';
import {
  cancelReservation,
  clearTrialGridRefresh,
  createCampaign,
  endCampaign,
  getCampaignDetails,
  getTrialBundles,
  pauseCampaign,
  refreshTrialGrid,
  reserveTrial,
  resumeCampaign,
  setCampaignDetails,
  setTrialBundles,
  updateCampaign,
} from './trials.action';

@Injectable({
  providedIn: 'root',
})
export class TrialsEffects {
  reserveTrial$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(reserveTrial),
      switchMap((payload) => {
        const commandRequest = this.commandRequestBuilderService
          .new(`/TrialCampaigns/${payload.payload.campaignId}/reserve`, 'POST', TrialEvents.TrialReserved)
          .withBody(payload.payload.customerInfo)
          .withWaitOn201Created();
        return combineLatest([this.dataService.commandRequest$(commandRequest), of(payload.payload)]);
      }),
      switchMap(([_res, payload]) => {
        this.router.navigate(['trials/campaign', payload.campaignId], {
          queryParams: { selectedTabIndex: 1 },
        });
        return [
          displayMessage({
            payload: {
              message: 'Trial was successfully reserved.',
              type: 'Success',
            },
          }),
        ];
      }),
    );
  });

  cancelReservation$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(cancelReservation),
      switchMap((payload) => {
        const commandRequest = this.commandRequestBuilderService
          .new(
            `/TrialCampaigns/${payload.payload.campaignId}/reservations/${payload.payload.reservationId}/cancel`,
            'PUT',
            TrialEvents.TrialUnreserved,
          )
          .withWaitOn200Ok();
        return combineLatest([this.dataService.commandRequest$(commandRequest), of(payload.payload.campaignId)]).pipe(
          catchError((error: unknown) => {
            refreshTrialGrid();
            clearTrialGridRefresh();
            return throwError(() => error);
          }),
        );
      }),
      switchMap(([_commandRequestRes, campaignId]) => {
        return [
          getCampaignDetails({ payload: campaignId }),
          displayMessage({
            payload: {
              message: 'Trial reservation successfully cancelled.',
              type: 'Success',
            },
          }),
          refreshTrialGrid(),
          clearTrialGridRefresh(),
        ];
      }),
    );
  });

  createCampaign$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(createCampaign),
      switchMap((payload) => {
        const commandRequest = this.commandRequestBuilderService
          .new('/TrialCampaigns/bundle', 'POST', TrialEvents.CampaignDescriptionChanged)
          .withBody(payload.payload)
          .withWaitOn201Created();
        return this.dataService.commandRequest$(commandRequest).pipe(
          catchError((error: unknown) => {
            this.router.navigate(['trials']);
            return throwError(() => error);
          }),
        );
      }),
      switchMap(() => {
        this.router.navigate(['trials']);
        return [
          displayMessage({
            payload: {
              message: 'New trial campaign created successfully.',
              type: 'Success',
            },
          }),
        ];
      }),
    );
  });

  getCampaignDetails$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getCampaignDetails),
      switchMap((payload) => {
        return this.dataService.getCampaignDetails$(payload.payload);
      }),
      switchMap((campaignDetails: CampaignDetails) => {
        return [setCampaignDetails({ payload: campaignDetails })];
      }),
    );
  });

  pauseCampaign$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(pauseCampaign),
      switchMap((payload) => {
        const requestUrl = `/TrialCampaigns/${payload.campaignId}/pause`;
        const commandRequest = this.commandRequestBuilderService
          .new(requestUrl, 'PUT', TrialEvents.CampaignPaused)
          .withWaitOn202Accepted();
        return combineLatest([this.dataService.commandRequest$(commandRequest), of(payload.campaignId)]);
      }),
      switchMap(([res, campaignId]) => {
        let msg = 'The trial campaign was paused successfully.';
        let type = 'Success';
        if (res.response?.status === 200) {
          msg = 'The trial campaign was already paused.';
          type = 'Warning';
        }
        return [
          displayMessage({
            payload: {
              message: msg,
              type: type,
            },
          }),
          getCampaignDetails({ payload: campaignId }),
        ];
      }),
    );
  });

  resumeCampaign$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(resumeCampaign),
      switchMap((payload) => {
        const requestUrl = `/TrialCampaigns/${payload.campaignId}/resume`;
        const commandRequest = this.commandRequestBuilderService
          .new(requestUrl, 'PUT', TrialEvents.CampaignResumed)
          .withWaitOn202Accepted();
        return combineLatest([this.dataService.commandRequest$(commandRequest), of(payload.campaignId)]);
      }),
      switchMap(([res, campaignId]) => {
        let msg = 'The trial campaign was resumed successfully.';
        let type = 'Success';
        if (res.response?.status === 200) {
          msg = 'The trial campaign was already resumed.';
          type = 'Warning';
        }
        return [
          displayMessage({
            payload: {
              message: msg,
              type: type,
            },
          }),
          getCampaignDetails({ payload: campaignId }),
        ];
      }),
    );
  });

  endCampaign$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(endCampaign),
      switchMap((payload) => {
        const requestUrl = `/TrialCampaigns/${payload.campaignId}/end`;
        const commandRequest = this.commandRequestBuilderService
          .new(requestUrl, 'PUT', TrialEvents.CampaignEnded)
          .withWaitOn202Accepted();
        return combineLatest([this.dataService.commandRequest$(commandRequest), of(payload.campaignId)]);
      }),
      switchMap(([res, campaignId]) => {
        let msg = 'The trial campaign was ended successfully.';
        let type = 'Success';
        if (res.response?.status === 200) {
          msg = 'The trial campaign was already ended.';
          type = 'Warning';
        }
        return [
          displayMessage({
            payload: {
              message: msg,
              type: type,
            },
          }),
          getCampaignDetails({ payload: campaignId }),
        ];
      }),
    );
  });

  getTrialBundles$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTrialBundles),
      switchMap(() => {
        return this.dataService.getTrialBundles$();
      }),
      switchMap((trialBundles: TrialBundle[]) => {
        return [setTrialBundles({ payload: trialBundles })];
      }),
    );
  });

  updateCampaign$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(updateCampaign),
      switchMap((payload) => {
        const requestUrl = `/TrialCampaigns/${payload.payload.id}/update`;
        const commandRequest = this.commandRequestBuilderService
          .new(requestUrl, 'PUT', (domainMessage: IDomainMessage) => {
            return (
              domainMessage.type === TrialEvents.CampaignNameChanged ||
              domainMessage.type === TrialEvents.CampaignDescriptionChanged
            );
          })
          .withBody(payload.payload.update)
          .withWaitOn202Accepted()
          .withErrorHandler((error: unknown) =>
            of({
              error: error,
              errorContext: payload.payload.id,
            } as IDomainUpdateResponse),
          );
        return combineLatest([this.dataService.commandRequest$(commandRequest), of(payload.payload.id)]);
      }),
      switchMap(([res, campaignId]) => {
        if (!res.error) {
          return [
            displayMessage({
              payload: {
                message: 'Trial Campaign updated successfully.',
                type: 'Success',
              },
            }),
            getCampaignDetails({ payload: campaignId }),
          ];
        }
        const actions: Action[] = [];
        const message = res.error.error.errorCode
          .split(/(?=[A-Z])/)
          .join(' ')
          .toLowerCase();
        actions.push(
          displayMessage({
            payload: {
              message: `Unable to update the trial campaign: The ${message}`,
              type: 'Error',
            },
          }),
        );
        actions.push(getCampaignDetails({ payload: campaignId }));
        return actions;
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private dataService: DataService,
    private commandRequestBuilderService: CommandRequestBuilderService,
    private readonly router: Router,
    private readonly agGridFacade: ReportFacade,
  ) {}
}
