import { createSelector } from '@ngrx/store';
import { AppState, TenantState } from '@ra-state';

export const selectTenantFeature = (state: AppState): TenantState => state.tenant;

export const selectTenantId = createSelector(selectTenantFeature, (state: TenantState) => state.tenantId);

export const selectTenantInfo = createSelector(selectTenantFeature, (state: TenantState) => state.tenantInfo);

export const selectTenantUtilityTokenEntitlements = createSelector(selectTenantFeature, (state: TenantState) =>
  state.tenantUtilityTokens?.tokenEntitlements.concat(state.tenantUtilityTokens?.disabledTokenEntitlements),
);

export const selectTenantUsers = createSelector(selectTenantFeature, (state: TenantState) => state.tenantUsers);

export const selectFilteredTenantUsers = createSelector(
  selectTenantFeature,
  (state: TenantState) => state.selectedTenantUsers,
);

export const selectRefreshTenantGrid = createSelector(selectTenantFeature, (state: TenantState) => state.refreshGrid);
