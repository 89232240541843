import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { LoggerService } from '@app/services/logger.service';
import { Operator, PermHelperService, Permission } from '@app/services/perm-helper.service';
import { Observable, Subscription, tap } from 'rxjs';

export type Strategy = 'render' | 'enable';
@Directive({
  selector: '[appPerms]',
})
export class HasPermsDirective implements OnInit, OnDestroy {
  requiredPerms: Permission[];
  operator: Operator = 'any';
  isHidden: boolean = true;
  strategy: Strategy = 'render';
  logger: LoggerService = new LoggerService().withContext(HasPermsDirective);
  private subscription: Subscription;
  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionsHelper: PermHelperService<Permission>,
    private renderer: Renderer2,
  ) {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = this.updateView();
  }

  @Input()
  set appPerms(permissions: Permission[]) {
    this.logger.debug('Setting required permissions', permissions);
    this.requiredPerms = permissions;
  }

  @Input()
  set appPermsOp(op: Operator) {
    this.logger.debug('Setting operator', op);
    this.operator = op;
  }
  @Input()
  set appPermsStrategy(strategy: Strategy) {
    this.logger.debug('Setting strategy', strategy);
    this.strategy = strategy;
  }

  checkPermission$(): Observable<boolean> {
    if (this.operator === 'all') {
      return this.permissionsHelper.hasAll$(this.requiredPerms);
    } else {
      return this.permissionsHelper.hasAny$(this.requiredPerms);
    }
  }
  private updateView(): Subscription {
    return this.checkPermission$()
      .pipe(
        tap((permCheckResult) => {
          if (permCheckResult) {
            if (this.isHidden) {
              this.viewContainer.createEmbeddedView(this.templateRef);
              this.isHidden = false;
            }
          } else {
            this.isHidden = true;
            this.viewContainer.clear();
          }
          if (this.strategy === 'enable') {
            if (this.isHidden) {
              this.viewContainer.createEmbeddedView(this.templateRef);
              this.isHidden = false;
            }
            if (!permCheckResult) {
              this.renderer.setAttribute(this.element.nativeElement.previousElementSibling, 'disabled', 'true');
            } else {
              this.renderer.removeAttribute(this.element.nativeElement.previousElementSibling, 'disabled');
            }
          }
        }),
      )
      .subscribe();
  }
}
