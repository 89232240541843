import { createAction, props } from '@ngrx/store';
import { FeatureFlag, FeatureFlagTarget, FeatureFlagUpdate, FlagValue, HarnessIdentifier } from '@ra-state';

export const initializeTarget = createAction(
  '[Feature-Flag] Initialize Target',
  props<{ payload: FeatureFlagTarget }>(),
);

export const closeTarget = createAction(
  '[Close-Target] Close Target',
  props<{ harnessIdentifier: HarnessIdentifier }>(),
);

export const setFeatureFlags = createAction(
  '[Feature-Flag] Set Feature Flags',
  props<{ flags: Record<string, FlagValue> }>(),
);

export const updateFeatureFlag = createAction(
  '[Feature-Flag] Update Feature Flag',
  props<{
    payload: FeatureFlagUpdate;
  }>(),
);

export const deleteFeatureFlag = createAction('[Feature-Flag] Delete Feature Flag', props<{ payload: FeatureFlag }>());

export const emptyHarnessAction = createAction('[EMTPY] Empty Action');
