import { Injectable, inject } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@rockwell-automation-inc/service';
import { Observable, map, shareReplay } from 'rxjs';
import { LoggerService } from './logger.service';

export type Operator = 'all' | 'any';
export enum Permission {
  CreateServiceRequest = 'create:servicerequest',
  CreateTrialCampaign = 'create:trialcampaign',
  ListEntitlement = 'list:entitlement',
  ListTenant = 'list:tenant',
  ListTrialCampaign = 'list:trialcampaign',
  ManageTrialcampaign = 'manage:trialcampaign',
  ListTrial = 'list:trial',
  ManageTrial = 'manage:trial',
  ListUser = 'list:user',
  ReadTenant = 'read:tenant',
  ReadUserRole = 'read:userrole',
  WriteTenant = 'write:tenant',
  WriteUser = 'write:user',
  //fake - for testing
  FakePerm = 'fakefakefake',
}

export type PermissionCondition<T> = {
  operator?: Operator;
  permissions: T[];
};
@Injectable({
  providedIn: 'root',
})
export class PermHelperService<T> {
  logger = inject(LoggerService).withContext(PermHelperService);
  helper = new JwtHelperService();
  permissionClaims$: Observable<Set<T>> = this.authService.getAccessToken$(false).pipe(
    map((token) => {
      const claims = this.helper.decodeToken(token);
      const permissions = new Set<T>(claims.permissions);
      claims.scope.split(/\s+/).forEach((s: any) => {
        permissions.add(s);
      });
      return permissions;
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  checkImpl$(requestedPerms: T[], op: Operator = 'any'): Observable<boolean> {
    let method = requestedPerms.some;
    if (op === 'all') {
      method = requestedPerms.every;
    }
    return this.permissionClaims$.pipe(
      map((perms) => {
        const result = method.call(requestedPerms, (p) => perms.has(p));
        this.logger.debug(op, result, requestedPerms);
        return result;
      }),
    );
  }
  hasAll$ = (requestedPerms: T[]): Observable<boolean> => this.checkImpl$(requestedPerms, 'all');
  hasAny$ = (requestedPerms: T[]): Observable<boolean> => this.checkImpl$(requestedPerms, 'any');
  checkPermission$(c: PermissionCondition<T>): Observable<boolean> {
    return this.checkImpl$(c.permissions, c.operator);
  }

  constructor(private authService: AuthService) {}
}
