/* eslint-disable no-throw-literal */

export enum ENV_VARS {
  AG_GRID_KEY = 'AG_GRID_KEY',
  CS_API_BASE_URL = 'CS_API_BASE_URL',
  CS_NOTIFICATIONS_BASE_URL = 'CS_NOTIFICATIONS_BASE_URL',
  HARNESS_KEY = 'HARNESS_KEY',
}
export function getEnv(key: ENV_VARS): any {
  if (!(key in window) || typeof window[key] === 'undefined') {
    throw new Error(`Env var not defined ${key}`);
  }
  const value = window[key];
  if (typeof value === 'string' && value === '') {
    throw new Error(`Env var is defined ${key} with value ''`);
  }

  return window[key];
}

export function getEnvOrDefault(key: ENV_VARS, defaultVal: any): any {
  try {
    return getEnv(key);
  } catch (e) {
    return defaultVal;
  }
}
