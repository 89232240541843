import { Injectable } from '@angular/core';
import { CommandRequestBuilderService } from '@app/services/command-request-builder.service';
import { AgGridEvents } from '@app/services/command-request.service';
import { environment } from '@environments/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ControlPageFacade,
  displayMessage,
  emptyAction,
  generateReport,
  IDomainMessage,
  ReportData,
  setPreviousReportData,
  SnackBarFacade,
} from '@ra-state';
import { DataService } from '@services/data.service';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReportEffects {
  generateReport$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(generateReport),
      switchMap((payload) => {
        const commandRequest = this.commandRequestBuilderService
          .new(payload.payload.requestEndUrl, 'POST', (domainMessage: IDomainMessage) => {
            if (domainMessage.type === AgGridEvents.JobCreated) {
              this.snackBarFacade.displayMessage({
                title: `Generating your ${payload.payload.reportType} report...`,
                message: `We'll send you a notification when it's ready.`,
                type: 'Info',
                customBtn: [],
              });
              this.controlPageFacade.decrementInFlightRequests();
            }
            return domainMessage.type === AgGridEvents.JobCompleted || domainMessage.type === AgGridEvents.JobErrored;
          })
          .withParams(payload.payload.context)
          .withBody({
            reportLink: `${environment.appConfiguration.adminPortalURL}${payload.payload.reportUiUrl}`,
            force: payload.force,
          })
          .withWaitOn201Created();
        return this.dataService.commandRequest$(commandRequest);
      }),
      switchMap((domainResponse) => {
        const domainResponseMessage = domainResponse.message as unknown as {
          type: string;
          data: { openSasUrl: string; downloadSasUrl: string };
        };
        const domainResponseBody = domainResponse.response?.body as ReportData;
        if (domainResponseMessage && domainResponseMessage.type === 'JobCompleted') {
          return [
            displayMessage({
              payload: {
                title: 'Your report is ready for download.',
                message: 'Link to download also available in notifications.',
                type: 'Success',
                customBtn: [
                  {
                    label: 'Download',
                    navigateTo: domainResponseMessage.data.downloadSasUrl,
                  },
                ],
              },
            }),
          ];
        } else if (domainResponseMessage && domainResponseMessage.type === 'JobErrored') {
          return [
            displayMessage({
              payload: {
                title: "We weren't able to generate your report.",
                message: 'Please try again.',
                type: 'Error',
              },
            }),
          ];
        } else if (domainResponseBody && domainResponseBody.status === 'Requested') {
          return [
            displayMessage({
              payload: {
                title: `We're already working on generating this report.`,
                message: `We'll notify you when it’s ready.`,
                type: 'info',
              },
            }),
          ];
        } else if (domainResponseBody && domainResponseBody.status === 'Completed') {
          return [setPreviousReportData({ payload: domainResponseBody })];
        } else {
          return [emptyAction()];
        }
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly dataService: DataService,
    private readonly commandRequestBuilderService: CommandRequestBuilderService,
    private readonly snackBarFacade: SnackBarFacade,
    private readonly controlPageFacade: ControlPageFacade,
  ) {}
}
