import { createReducer, on } from '@ngrx/store';
import { PageControl } from '@ra-state';
import { decrementReqs, incrementReqs, setProductCatalogSuccess, userLoginComplete } from './control-page.actions';

export const initialPageControlState: PageControl = { inflightRequests: 0 };

export const controlPageReducer = createReducer(
  initialPageControlState,
  on(incrementReqs, (state: PageControl): PageControl => {
    const newState = { ...state };
    newState.inflightRequests = state.inflightRequests + 1;
    return newState;
  }),
  on(decrementReqs, (state: PageControl): PageControl => {
    const newState = { ...state };
    newState.inflightRequests = state.inflightRequests - 1;
    return newState;
  }),
  on(
    userLoginComplete,
    (state: PageControl, { payload }): PageControl => ({
      ...state,
      loggedInUserProfile: payload,
    }),
  ),
  on(
    setProductCatalogSuccess,
    (state: PageControl, { payload }): PageControl => ({
      ...state,
      catalogInfo: payload,
    }),
  ),
);
