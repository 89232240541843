import { createReducer, on } from '@ngrx/store';
import { SessionManagement } from '../common-admin.model';
import { setUserActive, setUserActivityRecorded } from './session-management.actions';

export const initialSessionManagementState: SessionManagement = {
  userActivityRecorded: false,
  sessionTimedOut: false,
  extendSession: false,
  logoutSession: false,
  lastUserActivityRecordedAt: Date.now(),
};

export const sessionManagementReducer = createReducer(
  initialSessionManagementState,
  on(setUserActivityRecorded, (state: SessionManagement, { value }): SessionManagement => {
    if (value === true) {
      return { ...state, userActivityRecorded: value, lastUserActivityRecordedAt: Date.now() };
    }
    return { ...state, userActivityRecorded: value };
  }),
  on(setUserActive, (state: SessionManagement, { value }): SessionManagement => ({ ...state, isUserActive: value })),
);
