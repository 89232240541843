import { createReducer, on } from '@ngrx/store';
import { TrialCampaignStatus, TrialsState } from '@ra-state';
import { clearTrialGridRefresh, refreshTrialGrid, setCampaignDetails, setTrialBundles } from './trials.action';

export const initialTrialsState: TrialsState = {
  campaignDetails: {
    bundleId: '',
    createdByUser: '',
    createdByUserId: '',
    createdOn: '' as unknown as Date,
    description: '',
    endedByUser: '',
    endedByUserId: '',
    endedOn: '' as unknown as Date,
    id: '',
    name: '',
    partitionId: '',
    pausedByUser: '',
    pausedByUserId: '',
    pausedOn: '' as unknown as Date,
    redeemedTrials: 0,
    reservedTrials: 0,
    status: '' as TrialCampaignStatus,
    trialDurationInDays: '',
    trialRedemptionLimitInDays: 0,
    viewType: '',
    perTrialCredits: 0,
    credits: 0,
    trialsQuantity: 0,
    remainingTrialsQuantity: 0,
  },
  trialBundles: [
    {
      id: '',
      nameAndDescription: ['', ''],
      creditsAndProductCatalogs: [],
      name: '',
      description: '',
      credits: null,
      products: [],
    },
  ],
  refreshGrid: false,
};

export const trialsReducer = createReducer(
  initialTrialsState,
  on(
    setCampaignDetails,
    (state: TrialsState, { payload }): TrialsState => ({
      ...state,
      campaignDetails: payload,
    }),
  ),
  on(
    setTrialBundles,
    (state: TrialsState, { payload }): TrialsState => ({
      ...state,
      trialBundles: payload,
    }),
  ),
  on(
    refreshTrialGrid,
    (state: TrialsState): TrialsState => ({
      ...state,
      refreshGrid: true,
    }),
  ),
  on(
    clearTrialGridRefresh,
    (state: TrialsState): TrialsState => ({
      ...state,
      refreshGrid: false,
    }),
  ),
);
