import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, ErrorContext, LoginError } from '@ra-state';
import { UserProfile } from '@rockwell-automation-inc/service';
import {
  decrementReqs,
  incrementReqs,
  navigateToError,
  userLoginComplete,
  userLoginFailed,
} from './control-page.actions';
import { selectCatalogInfo, selectLoadingState, selectLoggedInUserProfile } from './control-page.selectors';

@Injectable({
  providedIn: 'root',
})
export class ControlPageFacade {
  isLoading$ = this.store$.select(selectLoadingState);
  loggedInUserProfile$ = this.store$.select(selectLoggedInUserProfile);
  catalogInfo$ = this.store$.select(selectCatalogInfo);

  incrementInFlightRequests(): void {
    this.store$.dispatch(incrementReqs());
  }
  decrementInFlightRequests(): void {
    this.store$.dispatch(decrementReqs());
  }

  setUserLoggedIn(userProfile: UserProfile): void {
    this.store$.dispatch(userLoginComplete({ payload: userProfile }));
  }
  setUserLoginFailed(loginError: LoginError): void {
    this.store$.dispatch(userLoginFailed({ payload: loginError }));
  }

  navigateToError(httpError: ErrorContext): void {
    this.store$.dispatch(navigateToError({ payload: httpError }));
  }

  constructor(private store$: Store<AppState>) {}
}
