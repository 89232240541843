import { createReducer, on } from '@ngrx/store';
import { UserState } from '@ra-state';
import { setOrganizations, setSecondaryEmailsSuccess, setUserId, setUserPreferences } from './user.actions';

export const initialUserState: UserState = {
  userId: '',
  name: '',
  location: '',
  email: '',
  company: '',
  idpConnectionName: '',
  secondaryEmails: [],
  organizations: [],
};

export const userStateReducer = createReducer(
  initialUserState,
  on(
    setUserPreferences,
    (state: UserState, { userState }): UserState => ({
      ...state,
      ...userState,
    }),
  ),
  on(
    setSecondaryEmailsSuccess,
    (state: UserState, { secondaryEmails }): UserState => ({
      ...state,
      secondaryEmails,
    }),
  ),
  on(
    setOrganizations,
    (state: UserState, { organizations }): UserState => ({
      ...state,
      organizations,
    }),
  ),
  on(
    setUserId,
    (state: UserState, { userId }): UserState => ({
      ...state,
      userId,
    }),
  ),
);
