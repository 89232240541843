import { inject, Injectable, OnDestroy } from '@angular/core';
import { HarnessFacade } from '@app/+state/feature-flags/harness.facade';
import { LoggerService } from '@app/services/logger.service';
import {
  Evaluation,
  Event,
  initialize,
  Options,
  Result,
  Target,
  VariationValue,
} from '@harnessio/ff-javascript-client-sdk';
import { FeatureFlag, FeatureFlagTarget, HarnessIdentifier } from '@ra-state';

export type HarnessInitializer = (apiKey: string, target: Target, options?: Options) => Result;

@Injectable({
  providedIn: 'root',
})
export class HarnessService implements OnDestroy {
  changeFeedMap = new Map<HarnessIdentifier, Result>();
  logger = inject(LoggerService).withContext(HarnessService);
  // TODO below hardcoded key
  HARNESS_KEY = window['HARNESS_KEY'] || this.logger.warn('HARNESS_KEY cannot be undefined');

  initialize = initialize;

  initializeTarget(featureFlagsTarget: FeatureFlagTarget): void {
    this.logger.log('Initializing feature flags with target: ', featureFlagsTarget);
    const initResult = this.initialize(this.HARNESS_KEY, featureFlagsTarget as Target);
    initResult.on(Event.ERROR, (err) => {
      this.logger.warn('Unknown failure in feature SDK. All experimental features disabled.', err);
      this.logger.error('Feature flag service failed');
    });
    initResult.on(Event.READY, this.onReadyCallback);
    initResult.on(Event.CHANGED, this.onChangeCallback);
    this.changeFeedMap.set(featureFlagsTarget.identifier, initResult);
  }

  closeChangeFeed(identifier: HarnessIdentifier): void {
    this.changeFeedMap.get(identifier)?.close();
    this.changeFeedMap.delete(identifier);
  }

  constructor(private harnessFacade: HarnessFacade) {}

  onReadyCallback: (flags: Record<string, VariationValue>) => void = (flags: Record<string, VariationValue>) => {
    this.harnessFacade.setFeatureFlags(flags);
  };

  onChangeCallback: (change: Evaluation) => void = (change: Evaluation) => {
    if (change.deleted) {
      this.logger.debug('Deleting feature flag', change.flag);
      this.harnessFacade.deleteFeatureFlag(change.flag as FeatureFlag);
    } else {
      this.logger.debug('Updating feature flag:', change.flag, change.value);
      this.harnessFacade.updateFeatureFlag({
        flag: change.flag as FeatureFlag,
        value: change.value,
      });
    }
  };
  ngOnDestroy(): void {
    this.changeFeedMap.forEach((value) => value.close());
  }
}

export class HarnessServiceError extends Error {}
