import { createReducer, on } from '@ngrx/store';
import { SnackBarMessage } from '@ra-state';
import { displayMessage } from './snackbar.action';

export const initialSnackbarState: SnackBarMessage = {};

export const snackBarReducer = createReducer(
  initialSnackbarState,
  on(
    displayMessage,
    (state: SnackBarMessage, { payload }): SnackBarMessage => ({
      ...state,
      data: payload,
    }),
  ),
);
