import { Injectable } from '@angular/core';
import { LoggerService } from '@app/services/logger.service';
import { getEnvironment } from '@app/shared/utils';
import { environment } from '@environments/environment';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { connect, ICatalogInfo, LoginErrorCode, signalRHubConnected } from '@ra-state';
import { ActionButtonStyles, IDialogConfig, NotificationType } from '@ra-web-tech-ui-toolkit/components';
import { DataService } from '@services/data.service';
import { HarnessService } from '@services/harness.service';
import { map, Observable, switchMap, tap } from 'rxjs';
import { CommandRequestBuilderService } from 'src/app/services/command-request-builder.service';
import { openWebTechDialog } from '../modal';
import {
  AppInitFinished,
  getProductCatalog,
  logoutSession,
  setProductCatalogSuccess,
  startAppInitializer,
  userLoginComplete,
  userLoginFailed,
} from './control-page.actions';

@Injectable({
  providedIn: 'root',
})
export class ControlPageEffects implements OnInitEffects {
  initializeApp$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(startAppInitializer),
      tap(() => {
        this.harnessService.initializeTarget({
          identifier: 'common-admin-portal',
          attributes: {
            Type: 'Application',
          },
        });
      }),
      switchMap(() => {
        return [AppInitFinished()];
      }),
    );
  });

  ngrxOnInitEffects(): Action {
    this.logger.log('Initializing effects');
    return startAppInitializer();
  }

  loginCompleted$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(userLoginComplete),
      switchMap(() => {
        return [connect(), getProductCatalog()];
      }),
    );
  });

  signalRHubConnected$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(signalRHubConnected),
      switchMap(() => {
        return [];
      }),
    );
  });

  getProductCatalog$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getProductCatalog),
      switchMap(() => {
        return this.dataService.getCatalogInfo$();
      }),
      switchMap((catalog: ICatalogInfo[]) => {
        return [setProductCatalogSuccess({ payload: catalog })];
      }),
    );
  });

  missingRolesErrorDialogConfig(env): IDialogConfig {
    return {
      title: 'Your account is not authorized',
      message: `Please request access via IIQ for FTHub Admin portal role(s) for ${env} environment `,
      buttons: [{ label: 'Close', buttonStyle: ActionButtonStyles.Outlined }],
    };
  }

  defaultErrorConfig: IDialogConfig = {
    title: 'Unable to sign you in',
    message:
      'We ran into an unexpected issue while signing you in. Please create a support ticket and we will help resolve the issue. ',
    buttons: [
      { label: 'Create ticket', buttonStyle: ActionButtonStyles.Main },
      { label: 'Close', buttonStyle: ActionButtonStyles.Outlined },
    ],
  };
  loginFailed$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(userLoginFailed),
      map(({ payload: loginError }) => {
        switch (loginError.errorCode) {
          case LoginErrorCode.AdminPortalRoleNotFound:
            const env = getEnvironment(environment.appConfiguration.csPortalUrl);
            return openWebTechDialog({
              config: this.missingRolesErrorDialogConfig(env),
              notificationType: NotificationType.Error,
              closeHandler: logoutSession(),
            });
          default:
            return openWebTechDialog({
              config: this.defaultErrorConfig,
              notificationType: NotificationType.Error,
              closeHandler: logoutSession(),
            });
        }
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private dataService: DataService,
    private commandRequestBuilderService: CommandRequestBuilderService,
    private harnessService: HarnessService,
    private logger: LoggerService,
  ) {
    this.logger.log('control page effects loads');
  }
}
