import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HarnessService } from '@services/harness.service';
import { map, Observable } from 'rxjs';
import { closeTarget, emptyHarnessAction, initializeTarget } from './feature-flags.actions';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsEffects {
  intializeTarget$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(initializeTarget),
      map((payload) => {
        this.harnessService.initializeTarget(payload.payload);
        return emptyHarnessAction();
      }),
    );
  });

  closeTarget$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(closeTarget),
      map((payload) => {
        this.harnessService.closeChangeFeed(payload.harnessIdentifier);
        return emptyHarnessAction();
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private harnessService: HarnessService,
  ) {}
}
