import { Configuration } from '@ra-state';
import { ENV_VARS, getEnvOrDefault } from '@shared/env';

const appConfiguration: Configuration = {
  production: false,
  name: 'Common Admin',
  csApiBaseUrl: getEnvOrDefault(ENV_VARS.CS_API_BASE_URL, 'https://common.lemans-sandbox.rockwellautomation.com/api'),
  csPortalUrl: 'https://home.lemans-sandbox.rockwellautomation.com',
  notificationsBaseUrl: getEnvOrDefault(
    ENV_VARS.CS_NOTIFICATIONS_BASE_URL,
    'https://api-notifications.lemans-sandbox.rockwellautomation.com',
  ),
  authConfig: {
    domain: 'factorytalkhub-dev.us.auth0.com',
    clientId: 'u6DI8DyO4gNOYQn73FRsT6TW5vhMSz5v',
    audience: 'https://lemans.common',
    redirectUri: 'https://common-admin.lemans-sandbox.rockwellautomation.com/callback',
    connection: 'Rockwell-SP-Admin',
    errorPath: '/sign-in',
  },
  returnToAfterLogout: 'https://common-admin.lemans-sandbox.rockwellautomation.com/sign-in',
  customSignInUrl: 'https://common-admin.lemans-sandbox.rockwellautomation.com/sign-in',
  adminPortalURL: 'https://common-admin.lemans-sandbox.rockwellautomation.com',
};

export const environment = {
  production: false,
  appConfiguration,
  ftHubPortalUrl: 'https://home.lemans-sandbox.rockwellautomation.com',
};
