import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { emptyAction } from '@ra-state';
import {
  ActionButtonStyles,
  DialogComponent,
  DialogService,
  IDialogConfig,
  NotificationType,
} from '@ra-web-tech-ui-toolkit/components';
import { ModalService } from '@services/modal.service';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { openAlertDialog, openConfirmDialog, openWebTechDialog } from './modal.action';

@Injectable({
  providedIn: 'root',
})
export class ModalEffects {
  openDialog(config: IDialogConfig, dialogType?: NotificationType): MatDialogRef<DialogComponent> {
    switch (dialogType) {
      case NotificationType.Success:
        return this.dialogService.openSuccessDialog(config);
      case NotificationType.Info:
        return this.dialogService.openInfoDialog(config);
      case NotificationType.Warning:
        return this.dialogService.openWarningDialog(config);
      case NotificationType.Error:
        return this.dialogService.openErrorDialog(config);
      default:
        return this.dialogService.openDialog(config);
    }
  }

  openConfirmDialog$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(openConfirmDialog),
      switchMap((payload) => {
        const confirmDialogConfig = payload.payload;
        const diaglogRef = this.openDialog(confirmDialogConfig.config, confirmDialogConfig.type);
        return combineLatest([diaglogRef.componentInstance.onClick, of(payload.payload)]);
      }),
      switchMap(([dialogBtn, payload]) => {
        const actions: Action[] = [];
        if (dialogBtn.label === 'Confirm') {
          actions.push(payload.action);
        }
        return actions;
      }),
    );
  });

  openAlertDialog$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(openAlertDialog),
      map((payload) => {
        const confirmDialogConfig = payload.payload;
        this.modalService.showModal(confirmDialogConfig);
        return emptyAction();
      }),
    );
  });

  openWebTechDialog$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(openWebTechDialog),
      switchMap((payload) => {
        const dialogRef = this.openDialog(payload.config, payload.notificationType);
        return combineLatest([dialogRef.componentInstance.onClick, of(dialogRef), of(payload)]);
      }),
      switchMap(([dialogButton, dialogRef, payload]) => {
        if (dialogButton.buttonStyle === ActionButtonStyles.Main && payload.mainBtnHandler) {
          payload.mainBtnHandler();
        }
        return combineLatest([dialogRef.afterClosed(), of(payload)]);
      }),
      map(([_closed, payload]) => {
        if (payload.closeHandler) {
          return payload.closeHandler;
        }
        return emptyAction();
      }),
    );
  });
  constructor(
    private actions$: Actions,
    private dialogService: DialogService,
    private modalService: ModalService,
  ) {}
}
