import { createReducer, on } from '@ngrx/store';
import { SignalRData } from '../common-admin.model';
import { disconnect, setConnectionState, setDomainMessageReceived, updateConnectionState } from './signalr.actions';

export const initialSignalRState: SignalRData = {};

export const signalrReducer = createReducer<SignalRData>(
  initialSignalRState,
  on(
    setConnectionState,
    (state: SignalRData, { payload }): SignalRData => ({
      ...state,
      connectionState: payload,
    }),
  ),
  on(
    updateConnectionState,
    (state: SignalRData, { payload }): SignalRData => ({
      ...state,
      connectionState: payload,
    }),
  ),
  on(
    setDomainMessageReceived,
    (state: SignalRData, { payload }): SignalRData => ({
      ...state,
      domainMessage: payload,
    }),
  ),
  on(disconnect, (state: SignalRData): SignalRData => {
    return { ...state };
  }),
);
