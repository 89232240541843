import { Injectable } from '@angular/core';
import { createAction, createReducer, createSelector, on, props, Store } from '@ngrx/store';
import { AppState, ReportContext, ReportData, ReportState } from '@ra-state';

export const selectReportsFeature = (state: AppState): any => state.reports;
export const selectPreviousReportData = createSelector(
  selectReportsFeature,
  (state: ReportState) => state.previousReportData,
);

export const generateReport = createAction(
  '[REPORTS] Create Report',
  props<{ payload: ReportContext; force: boolean }>(),
);

export const setPreviousReportData = createAction('[REPORTS] Set Report Data', props<{ payload: ReportData }>());

export const reportInitialState: ReportState = {};

export const reportsReducer = createReducer(
  reportInitialState,
  on(
    setPreviousReportData,
    (state: ReportState, { payload }): ReportState => ({
      ...state,
      previousReportData: payload,
    }),
  ),
);

@Injectable({
  providedIn: 'root',
})
export class ReportFacade {
  previousReportData$ = this.store$.select(selectPreviousReportData);

  constructor(private store$: Store<AppState>) {}

  generateReport(reportContext: ReportContext, force: boolean = false): void {
    this.store$.dispatch(
      generateReport({
        payload: reportContext,
        force,
      }),
    );
  }
}
