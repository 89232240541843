import { createAction, props } from '@ngrx/store';
import {
  InviteAdmin,
  PaginatedRequest,
  PaginatedResponse,
  RemoveUserRequest,
  TenantUser,
  TenantUtilityTokens,
  TransferOwnership,
} from '../common-admin.model';

export const refreshTenantGrid = createAction('[TENANT] Refresh Tenant Grid');
export const clearTenantGridRefresh = createAction('[TENANT] Clear Tenant Grid Refresh');

export const setTenantId = createAction('[TENANT] Set Tenant Id', props<{ tenantId: string }>());

export const getTenantUtilityTokens = createAction(
  '[TENANT UTILITY TOKENS] Get Current Tenant Utility Tokens and Entitlements',
  props<{ value: string }>(),
);

export const setTenantUtilityTokens = createAction(
  '[TENANT UTILITY TOKENS] Set Current Tenant Utility Tokens',
  props<{ payload: TenantUtilityTokens }>(),
);

export const getTenantUsers = createAction(
  '[TENANT] Get all users of a Tenant',
  props<{ payload: PaginatedRequest }>(),
);

export const setTenantUsers = createAction(
  '[TENANT] Set all users of a Tenant',
  props<{ tenantUsers: TenantUser[] }>(),
);

export const getTenantFilteredUsers = createAction(
  '[TENANT] Get selected users of a Tenant',
  props<{ payload: PaginatedRequest }>(),
);

export const setTenantFilteredUsers = createAction(
  '[TENANT] Set selected users of a Tenant',
  props<{ selectedTenantUsers: PaginatedResponse<{}> }>(),
);

export const transferOwnership = createAction(
  '[TENANT] Initiate Transfer Ownership',
  props<{ payload: TransferOwnership }>(),
);

export const createAdminInvitation = createAction('[INVITATION] Create Invitations', props<{ payload: InviteAdmin }>());

export const getTenantDetails = createAction('[TENANT] Get Tenant Details', props<{ value: string }>());

export const setTenantDetails = createAction('[TENANT] Set Tenant Details', props<{ payload: any }>());

export const removeUserFromTenant = createAction('[TENANT] Remove User From Tenant', props<{ payload: RemoveUserRequest }>());

export const unarchiveTenant = createAction(
  '[TENANT] Unarchive Tenant Status',
  props<{ tenantId: string; orgName: string; archive: boolean }>(),
);

export const archiveTenant = createAction(
  '[TENANT] Archive Tenant Status',
  props<{ tenantId: string; archive: boolean }>(),
);
